.courseItem {
    background-color: white;
    width: 30rem;
    border-radius: .5rem;
    box-shadow: 0 .3rem .3rem rgba(0, 0, 0, .2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: .3s;
    position: relative;
    margin:1rem;
}



.courseItem:hover {
    transform: scale(1.1);
    box-shadow: 0 .6rem .6rem rgba(0, 0, 0, .2);
}

.pricing {
    position: absolute;
    padding: .5em;
    width: 100%;
    background-color: var(--primary);
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 .3rem .3rem rgba(0, 0, 0, .2);
    top: 0;
    right: 0;
    transform: rotate(20deg);
}

.pricing p {
    margin: 0;
    font-weight: 800;
    font-size: 1.6rem;
    color: white;
}

.purchased {
    position: absolute;
    padding: .5em;
    width: 100%;
    background-color: #333;
    width: fit-content;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 .3rem .3rem rgba(0, 0, 0, .2);
    top: 1rem;
    left:0rem;
   
}

.purchased p {
    margin: 0;
    padding: 0 .3rem;
    font-weight: 800;
    font-size: 1.6rem;
    
}