
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');

html {
  margin: 0;
  position: relative;
  min-height: 100vh;
  font-size: 62.5%;
  
}
body {
  margin: 0; 
  min-height: 650px;
  font-family: 'Red Hat Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./img/Blue-bg.png");
  background-repeat: repeat;
  background-size: 350px;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  color: #27A1B7;
}

.header-container {
  position: fixed;
  z-index: 500 !important;
}

.no-scrollbar {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none; 
}

.no-scrollbar::-webkit-scrollbar {
  width: 0px; 
  background: transparent;
}

.center {
  margin: 0 auto;
  text-align:  center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.left {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1750px;
}

.page-wrapper {
  margin: 0 auto;
  padding-top: 9rem;
  margin-bottom: 5.6rem;
  width: 100%;
  max-width: 1750px;
  min-height: calc(100vh - 144px);
}

.page-title {
  position: fixed;
}

.col {
  display: flex;
  flex-direction: column;
}

.col20p {
  width: 20%;
}

.col30p {
  width: 30%;
}

.col40p {
  width: 40%;
}

.col50p {
  width: 50%;
}

.col60p {
  width: 60%;
}

.col80p {
  width: 80%;
}

input {
  outline: none;
}

:root {
  --primary-100: #5DCFE3;
  --primary: #27A1B7;
  --primary-500: #08778B;

  --secondary-100: #EEEEEE;
  --secondary: #8B8B8B;
  --secondary-500: #434343;

  --input-color: #99A3BA;
  --input-border: #CDD9ED;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;

  --input-border-focus: #27A1B7;

  --group-color: #333;
  --group-border: var(--input-border);
  --group-background: #ededed;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: var(--primary);

}

a {
  color: var(--primary);
}

a:hover {
  color: var(--primary-500);
}

.helogale {
  color: white;
  text-decoration: none;
  opacity: .6;
}

.helogale:hover {
  color: white !important;
  opacity: 1;
}

@media only screen and (max-width:768px) {
  .page-wrapper {
    padding-top: 8rem;
  }
}