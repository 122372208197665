.dashboard-wrapper {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
}

.page-title {
    margin: 0 3rem;
    padding: .5rem;
    width: fit-content;
    border-radius: .5rem;
    background-color: var(--primary);
    color: white;
    padding-left: 16rem;
    padding-right: 1rem;
    z-index: 20;
}

.content-wrapper {
    margin: 0;
    padding: 0;
    padding-top: 8rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.account-settings {
    width: 100%;
    max-width: 90rem;
}

.dashboard-content-left {
    margin: 0 5%;
    max-width: 750px;
}

.dashboard-content-right {
    margin: 0;
    max-width: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.profile-col-container {
    min-height: 20rem;
    margin-top: -10rem;
    padding-top: 10rem;
    text-align: center;
    width: 100%;
    z-index: 5;
}

.profile-col-container h3 {
    color: var(--primary);
    margin: 1rem .5rem;
    margin-bottom: 0px;
    font-size: 3.5rem;
    font-weight: 800;
}

.profile-col-container p {
    color: var(--secondary);
    font-weight: 500;
    font-size: 1.6rem;
}

.webcam {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    overflow: hidden;
}

@media only screen and (max-width:768px) {
    .dashboard-content-left {
        width: 100vw;
        padding: 0;
        margin: 0;
    }
    .content-wrapper {
        padding: 0;
    }
    .page-title {
        margin-left: -140px;
        position: absolute;
        border-radius: 0 0 5px 0;
    }

    .content-wrapper {
        flex-direction: column;
    }

    .dashboard-content-right {
        width: 100%;
        max-width: none;
    }

    .account-settings {
        margin-top: 2rem;
    }
}