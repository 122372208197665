.progressContainer {
    margin-top: 0rem;
    display: flex;
    justify-content: center;
    background-color: #333;
    border-bottom: .3rem solid var(--primary);
}

.gaugeWrapper {
    box-shadow: none;
    transform: scale(.9);
    margin: 0;
    background-color: transparent;
}