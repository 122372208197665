.video-wrapper {
  width: 640px;
  height: 360px;
  background: aliceblue;
}

.buttons-wrapper {
  display: flex;
  width: 640px;
  margin-top: 10px;
  justify-content: center;
}

.buttons-wrapper > button {
  margin: 5px;
}

.title-postion {
position: absolute;
top: 90px;
left: 0;
}

