.courseListWrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1220px;
}

.courseList {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    
}