.dashboardWrapper {
    margin: 0 auto;
    padding-top: 8rem;
    margin-bottom: 5rem;
    width: 100%;
    /* max-width: 1750px; */
    min-height: calc(100vh - 130px);
    display: flex;
    justify-content: center;
}



.contentWrapper {
    margin: 0;
    margin-top: 2rem;
    
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.contentContainer {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 190rem;
    display: flex;
}

.progressInfoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-content-left {
    margin: 0 5%;
    max-width: 750px;
}

.dashboard-content-right {
    margin: 0;
    max-width: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.profile-col-container {
    min-height: 20rem;
    margin-top: -10rem;
    padding-top: 10rem;
    text-align: center;
    width: 100%;
    z-index: 5;
}

.profile-col-container h3 {
    color: var(--primary);
    margin: 1rem .5rem;
    margin-bottom: 0px;
    font-size: 3.5rem;
    font-weight: 800;
}

.profile-col-container p {
    color: var(--secondary);
    font-weight: 500;
    font-size: 1.6rem;
}

.webcam {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    overflow: hidden;
}

@media only screen and (max-width:768px) {
    .dashboard-content-left {
        width: 100vw;
        padding: 0;
        margin: 0;
    }
    .content-wrapper {
        padding: 0;
    }
    .page-title {
        margin-left: -140px;
        position: absolute;
        border-radius: 0 0 5px 0;
    }

    .content-wrapper {
        flex-direction: column;
    }

    .dashboard-content-right {
        width: 100%;
        max-width: none;
    }
}