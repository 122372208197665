.formWrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 50rem;
    margin-left: 2rem;
    z-index: 5;
}

.form {
    width: 100%;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
}

.formWrapper form label {
    color: white;
    font-size: 1.4rem;
}

.formTitle {
    background-color: var(--primary);
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 4rem;
    margin: 1.8rem 0;
    border-radius: .5rem;
}

.input {
    background-color: transparent;
    outline: none;
    border-radius: 0 !important;
    border: 0;
    border-bottom: 1px solid white;
    color: white;
}

.inputWrapper {
    display: flex;
    flex-direction: column; 
}

.actions {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;
}

.enterBtn, .registerBtn {
    font-size: 1.9rem;
    width: 45%;
    margin: 0;
   
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white !important;
}

.enterBtn {
    color: white;
}

.registerBtn {
    background-color: white;
    color: #000;
    border: rgb(190, 190, 190);
}

.registerBtn:after {
    background-color:rgb(190, 190, 190);
    color: #000;
}

.sentWrapper {
    margin: 2rem 0 ;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sentText {
    margin: 0 ;
    padding: 0;
    font-size: 1.8rem;
    color: white;
    font-weight: 300;
    text-align: center;
    white-space: pre-wrap;
}

.errorInputLabel {
    margin-left: 1rem;
    color: red;
    background-color: rgba(500, 10, 10, .2);
    transition: .3s;
    padding: 0 1rem;
    padding-top: .5rem;
    border-radius: .5rem .5rem 0 0;
}

.errorInput {
    background-color: rgba(500, 10, 10, .2);
    transition: .3s;
}

.noError {
    background-color: rgba(500, 10, 10, .0) !important;
}

@media screen and (max-width: 1018px) {
    .formTitle {
        font-size: 3vw;
        
    }
}

@media screen and (max-width: 624px) {

    .formTitle {
        font-size: 5vw;
        margin-top: 0;
        border-radius: 0;
    }

    .formWrapper {
        width:100%;
        max-width: none;
        margin-left: 0;
        height: 60vh;
        margin-top: -5rem;
    }

    .form {
        width: 90%;
        padding: 0 5%;
        justify-content: space-between;
    }

    .inputWrapper {
        padding-top: 2rem;
    }

}