.wrapper {
    margin-top: 1rem;
    padding: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
}

.title {
    margin: 0;
    padding: 0;
    color: #c0c0c0;
    width: 100%;
    text-align: left;
}


