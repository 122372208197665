

.paymentConatiner{
    padding: 0px;
    display: flex;
    flex-direction: column;
}

.price {
    padding: 1rem;
    font-size: 3rem;
    font-weight: 700;
    color: white;
    background-color: #333;

    margin: 0;
    text-align: center;
}

.priceSub {
    margin-top: 0;
    text-align: center;
    color: var(--secondary-500);
    padding-bottom: 5px;
    border-bottom: 1px dotted var(--secondary);
}

.action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.action button {
    font-size: 2rem;
}

.actionNotice {
    width: 100%;
    text-align: center;
}

.actionNotice p {
    color: var(--primary);
    font-weight: 600;
    font-size: 1.8rem;
    
}

.actionButton {
    margin: 5px 0;
}
