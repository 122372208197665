.contentWrapper {
    width: 95%;
    min-height: 30rem;
    background-color: #333;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    box-shadow: 0 .2rem .5rem rgba(0, 0, 0, .2);
    color: white;
}

.container {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    width: 100%;
    max-width: 190rem;
}


.mainTitle {
    color: var(--primary);
    font-size: 4.5rem;
    margin: .6rem 0;
}

.col {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:  flex-start;
    position: relative;
}

.courseDetails {
    position: absolute;
    top: 2rem;
   
    right: 2rem;
    background-color: whitesmoke;
    padding: 1rem;
    box-shadow: 0 .2rem .5rem rgba(0, 0, 0, .2);
    border: .1rem #333 solid;
}

.pricing {
    background-color: #333;
    width: 100%;
    padding: 2rem 0;
    text-align: center;
    border-radius: .5rem;
}
.price {
    margin: 0;
    font-size: 3rem;
    color: #fff;
}

.buyNowContainer {
   display: flex;
   margin: .5rem 0;
   height: 5.8rem;
}

.buyBtn {
    margin: 0;
    flex-grow: 2;
    font-size: 2.8rem;
}

.featureContainer {
    display: flex;
    width: 100%;
}

.features {
    margin: 0;
    font-size: 1.2rem;
    width: fit-content;
}

.icon {
    margin: 0 .5rem;
}

.seperator {
    margin: 0 1rem;
    color: var(--primary);
}

.descContainer {
    display: flex;
}

.desc {
    font-size: 1.6rem;
}

.lessonNumber {
    margin-left: .5rem;
}

.amount {
    margin-right: .5rem;
}