.wrapper {
    width: 95%;
    margin: 0 auto;
    max-width: 1220px;
    min-height: 100%;
    background-color: whitesmoke;
    padding: 2rem;
    margin-bottom: 10rem;
    box-shadow: 0 .2rem .2rem rgba(0, 0, 0, .3);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lessonTitle {
    font-size: 3rem;
    font-weight: 300;
    margin: .5rem;
    color: var(--secondary);
    border-bottom: .1rem solid var(--primary);
    flex-grow: 2;
}

.body {
    margin: 2rem 0;
}