.wrapper {
    margin: 0;
    margin-left: 1rem;
    padding: 0;
    font-size: 1.6rem;

}

.isCompleteWrapper {
    margin: 0;
    padding: 1.5rem 2rem;
    background-color: green;
    border: .1rem solid darkgreen;
    color: white;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.isCompleteWrapper p {
    margin: 0;
    margin-left: 1rem;
}

.button {
    margin: 0;
    padding: 0;
    padding: 1.5rem 2rem;
    font-family: 'Red Hat Display', sans-serif;
    font-size: 1.6rem;
    font-weight: 300;
}
.button:hover {
    color: white;
}

.button span {
    margin-right: 1rem;
}

.button p {
    margin: 0rem;
    padding: 0;
    z-index: 1;
}