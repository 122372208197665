.sidebar {
    margin: 0;
    padding: 0;
    position: fixed;
    top: 8rem;
    bottom: 0;
    left: 0;
    width: 280px;
    display: flex;
    flex-direction: column;
    background-color: var(--group-background);
    box-shadow: 0 .2rem .4rem rgba(0, 0, 0, .3);
}