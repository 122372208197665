.top-links {
    list-style: none;
    margin:0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-items: center;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.6rem;
  }

  .top-links li {
    margin: 1rem;
  }

  .top-links a, .top-links__logout{
    color: #818181;
    text-decoration: none;
    
  }

  .top-links a {
    padding: 0.5rem;
  }


.top-links a:hover,
.top-links a.active, 
.top-links__logout:hover
 {
  
  color: #27A1B7;;
  
}

.top-links__logout:active{
  color: #818181;
}

.top-links__logout {
    cursor: pointer;
    background: transparent;
    font: inherit;
    border: none;
  }
  
  .top-links__logout:focus {
    outline: none;
    color: #818181;
  }
  
  .top-links__logout:hover,
  .top-links__logout:active {
    color: #27A1B7;
  }

  .menu-parent {
    position: relative;

  }

  .nav-submenu {
    position: absolute;
    top: 2rem;
    left:0;
    display: block;
    opacity: 0;
    background-color: var(--primary);
    width: 20rem;
    color: white;
    list-style: none;
    padding: .5rem;
    box-shadow: 0 .2rem .2rem rgba(0, 0, 0, .4) ;
    transition: .3s;
  }

  .subnav-link  {
    color: white !important;
  }

  .nav-submenu li {
    margin: 0;
    padding: 1rem;
  }

  .nav-submenu li:hover  {
    background-color: var(--secondary);
  }

  .menu-parent:hover .nav-submenu {
    opacity: 1;
    z-index: 900;
  }
  
  @media (max-width: 768px) {

    .top-links {
      display: none;
    }

  }
  