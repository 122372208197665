
.pageTitle {
    position: fixed;
    top: 8rem;
    left: -4rem;
    margin: 0 3rem;
    padding: .5rem;
    width: fit-content;
    border-radius: 0 0 .5rem  0;
    background-color: var(--primary);
    color: white;
    padding-left: 16rem;
    padding-right: 1rem;
    z-index: 20;
}