.coursesWrapper {
    margin: 0 auto;
    padding-top: 8rem;
    margin-bottom: 5rem;
    width: 100%;
    /* max-width: 1750px; */
    min-height: calc(100vh - 130px);
    display: flex;
}

.pageTitle {
    margin: 0 3rem;
    padding: .5rem;
    width: fit-content;
    border-radius: .5rem;
    background-color: var(--primary);
    color: white;
    padding-left: 16rem;
    padding-right: 1rem;
    z-index: 20;
}

.contentWrapper {
    margin: 0;
    margin-top: 2rem;
    margin-left: 30rem;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.sidebarContent {
    width: 100%;
    margin-top: 10rem;
    padding: 2rem;
    
}

.sidebarContent p {
    color: var(--group-color);
    font-size: 1.6rem;
}