footer {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 500;
    background-color:#27A1B7;
    box-shadow: 0px -2px 3px rgba(0, 0, 0, .2);
    
}


.main-footer__shell {
    margin: 0 auto;
    padding: 0 3rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1756px;
   
}


.main-footer__brand {
    padding: 2%;

}

.main-footer__links {
    padding: 1.5rem;
    float: left;
}

@media (max-width: 391px) {
    .main-footer__links {
        margin:0;
        width: 100%;
    }
  }

.main-footer__contact {
    
    margin-right: 1rem;
    padding: 1.5rem;
    float: right;
}

.main-footer__bottom {
    margin: 0;
    padding: .5rem;
    padding-top: 1.5rem;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.4) !important;
    background-color: #27A1B7;
    font-size: 1.4rem;

}
.main-footer__bottom p {
  color: rgba(255, 255, 255, 0.6) !important;
}

