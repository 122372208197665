.wrapper {
    margin: 1rem;
    margin-top: 3rem;
    width: 100%;
    color: grey;
    font-size: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapper h1 {
    font-size: 2rem;
    text-align: center;
}