.postBody {
    margin: 1rem 0;
    padding: 1rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid #c2c2c2;
    width: 100%;
}

.header {

}

.senderName {
    margin: 2rem;
    margin-bottom: 0;
    font-size: 1.4rem;
}

.bodyText {
    color: grey;
    font-size: 1.8rem;
    margin-bottom: 2rem;
    padding: 2rem;
    padding-top: 0;
}

.postedTime {
    width: 98%;
    text-align: right;
    font-size: 1.4rem;
    font-weight: 300;
    border-top: 0.1rem solid lightgrey;
}

.postedTime span {
    color: var(--secondary);
}