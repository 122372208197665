.lesson {
    background: #eee;
    padding: .5rem 1rem;
    margin: .5rem 0;
    color: #777;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
}

.lessonTitle {
   font-weight: 300;
   margin-left: 1rem;
   font-size: 1.4rem;
}

.lessonNumber {
    margin: 0 2rem;
    width: 10rem;
    text-align: center;
    font-size: 1.4rem;
}

.iconWrapper {
    margin: 0;
    padding: 0;
    width: 6rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.icon {
    padding-right: 1rem;
    color: var(--primary);
}