
#ringWrapper {
    margin: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1.5rem;
    min-height: 1.5rem;
    position: relative;
}
    

#ring {
    margin: 0;
    height: 1rem;
    width: 1rem;
    border: 3px solid var(--primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    font-size: 1.2rem;
}

#line {
    position: absolute;
    left: .6rem;
    top:calc(50% + .5rem);
    margin: 0;
    height: 120%;
    width: 1rem;
    border-left: 3px solid var(--primary);
    
}

