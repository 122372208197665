#navIndexWrapper {
    margin: 0;
    margin-bottom: 5rem;
    width: 100%;
    position: relative;
    display: flex;
    flex-grow: 2;
    overflow-y: scroll;
}
.indexList {
    padding-left: 0;
    list-style: none;
    width: 100%;
}

.indexItem {
    margin: 0;
    padding: 1rem .3rem;
    border-bottom: .1rem solid #ccc;
    background-color: whitesmoke;
    color: #666;
    display: flex;
    transition: .3s;
    cursor: pointer;

}

.indexItem:hover {
    background-color: #ccc;
    
}

.indexItem p {
    color: #666;
    font-size: 1.2rem;
    margin-left: 1rem;
}

.active {
   
    border-right: .5rem solid var(--primary);
  
}

