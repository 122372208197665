.form {
    border-radius: 5px;
}

.input {
    background-color: whitesmoke;
    padding: 10px;
    margin: 10px 0;
}

.action {
    display: flex;
    
}

.button {
   width: 100%;
    font-size: 2rem;
    margin-left: 0;
    margin-right: 0;
}