.index {
    margin: 0;
    margin-bottom: 1rem;
    padding: 0rem ;
    border-bottom: .1rem solid #ccc;
    background-color: whitesmoke;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
}

.indexContent {
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.indexContent p {
    color: #666;
    padding: 1rem;
    margin: 0 1rem;
    margin-top: -1.5rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
}