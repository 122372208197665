.wrapper {
    margin: 5rem 0;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #08778B;
    background-image: url("../../../img/White-bg.png");
    background-repeat: repeat;
    background-size: 350px;
    width: 100%;
    max-height: 50rem;
    border: .2rem solid var(--primary);
}