.postForm {
    margin-top: 1rem;
    padding: 1rem;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #c2c2c2;
}

.postInput {
    border-radius: 0 !important;
    border: none;
    border-bottom: 1px solid var(--primary);
    flex-grow: 2;
}

.askButton {
    width: 4rem;
    border: none;
    background-color: transparent;
    color: grey;
    font-size: 2.5rem;
    transition: .3s;
}

.askButton:hover  {
    color: var(--primary);
    font-size: 2.5rem;
    transform: scale(1.1);
}

.loadingDots div {
    margin-top: 1rem;
    background-color: var(--primary);
}

.closedWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.closedTitle {
    border-bottom: 1px solid var(--primary);
    color: var(--primary);
}