#wrapper {
    margin: 0;
    padding: 0;
    position: relative;
    min-height:95vh;
    display: flex;
    align-items: stretch;
}

#leftContiner, 
#rightContainer {
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
}

#leftContiner {
    background-color: var(--primary);
    /* background: url("../../images/dig01.png") no-repeat center center; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
}

#rightContainer {
    width: 50%;
  
     justify-content: flex-start;
    align-items: center;
}

.branding {
    margin-right: 2rem;
    width: 80%;
    max-width: 60rem;
}

.authLangWrapper {
    position: fixed;
    top: 2rem;
    right: 2rem;
    z-index: 2000;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .4);
    z-index: 0;
}



@media screen and (max-width: 624px) {

    #wrapper {
        flex-direction: column;
    }
    
    #leftContiner, 
    #rightContainer {
        justify-content: center;
        width: 100%;
    }

    #leftContiner {
        min-height: 30vh;
    }

    #rightContainer {
        min-height: 65vh;
        align-items: flex-start;
       
    }

    .branding {
        margin-right: 0rem;
        width: 50%;
        
    }

    .authLangWrapper {
        position: absolute;
        top: .2rem;
        right: .2rem;
    }

    .langSel {
        background-color: black !important;
        color: var(--primary) !important;
    }

}