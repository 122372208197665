nav {
  display: inline-flex;
  float: right;
}

.header-text {
  color: white;
  display: inline;
}

.header-container {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 5;
}

.header-container__shell {
  margin: 0 auto;
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.header-conatiner__top,
.header-conatiner__nav_top {
  margin: 0;
  padding: 5px;
  width: 100%;
  display: flex;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
}

.wrapper {
  display: flex;
  align-items: center;
}

.header-conatiner__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  background-color: #27a1b7;
  z-index: 1000;
}

.header-conatiner__nav_top {
  height: 30px;
  background-color: #eeeeee;
  z-index: 999;
}

.header-container__info {
  display: flex;
  justify-content: center;
  padding: 3px 15px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}

.main-brand {
  margin: 0;
  margin-top: 45px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 3px solid #27a1b7;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  
}

.main-brand img {
  margin-top: -12px;
  width: 55px;
  height: 55px;
}

.header-brand-text {
  display: none;
}

.header-container__title {
  color: white;
}

.header-container__nav {
  list-style: none;
}

.header-container__link {
  display: inline;
}

.main-navigation__menu-btn {
  margin: 8px 3px;
  width: 2.5rem;
  height: 2rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2rem;
  height: 2px;
  background: white;
}

.main-navigation__header-nav {
  display: flex;
}

.header-container__brand {
  width: 50%;
  display: grid;
  font-family: 'Roboto', sans-serif;
}

@media only screen and (max-width: 768px) {
  .main-brand {
    display: none;
  }

  .header-brand-text {
    display: flex;
    padding-left: 10px;
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .header-container__info {
    width: 100%;
    font-size: 1.5rem;
  }
  .header-email {
    display: none;
  }
}
