.wrapper {
    min-height: 100%;
    padding: 2rem;
    margin-bottom: 10rem;
    display: flex;
    flex-direction: column;
    border-top: 0.1rem solid var(--secondary-100);
}

.header {
    margin: 0 auto;
    width: 100%;
    max-width: 80rem;
    border-bottom: .1rem dashed var(--secondary);
}

.header h1 {
    font-size: 3rem;
    font-weight: 300;
    color: var(--secondary);
}

.body {
    margin: 0 auto;
    width: 100%;
    max-width: 80rem;
    min-height: 30rem;
    border-bottom: .1rem solid var(--primary);
}