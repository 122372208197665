.courseIndexWrapper {
    margin: 1rem 0;
    width: 95%;
    max-width: 170rem;
    height: 50rem;
}

.courseIndexWrapper ul {
    padding-left: 0;
    list-style: none;
    background-color: whitesmoke;
    padding: .5rem;
    box-shadow: 0 .2rem .5rem rgba(0, 0, 0, .2);
}