#my-account-settings {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    background: whitesmoke;
    padding: 20px;
    border-radius: 8px;
    justify-content: flex-start;
}

.my-account-settings-content {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--primary);
    border-radius: 8px;
    width: 100%;
}

.my-account-settings-title {
    margin-top: -1rem;
    background-color: whitesmoke;
    width: fit-content;
    padding: 0 10px;
    margin-left: 20px;
}

.my-account-settings-inner-content {
    padding: 10px;
    display: flex;

}

.avatar-settings-container {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.personal-settings-container {
    margin-left: 20px;
    display: flex;
}

.personal-settings-container form {
    display: flex;
    flex-direction: column;
}

.personal-update-btn {
    margin: 0;
    width: 100%;
}

.personal-settings-container-p {
    margin: 0;
    margin-bottom: 3px;
    color: grey;
}

.update-course-modal__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ma-loading-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left:0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 820px){
    .my-account-settings-inner-content {
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .personal-settings-container {
        margin: 2rem 0;
        width: 100%;
    }

    .personal-settings-container form {
        width: 100%;
    }


}