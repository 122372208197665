.downloadableContainer {
    background-color: var(--secondary);
    margin: 0 .5rem;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
    flex-grow: 2;
    font-size: 6rem;
   
}

.downloadableContainer a {
    margin: 0;
    font-size: 2rem;
    color: white;
}